import styled from "styled-components";
import { SetStateAction, Dispatch } from "react";

const ToggleContainer = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: center;
  margin-bottom: 45px;

  .tab {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background-color: #d5d5d5;
    margin: 0 5px;
    &.active {
      background-color: #003a70;
    }
  }
`;

interface Props {
  showPlayerStats: boolean;
  setShowPlayerStats: Dispatch<SetStateAction<boolean>>;
}

const MobileTabList = ({ showPlayerStats, setShowPlayerStats }: Props) => {
  return (
    <ToggleContainer role="tablist">
      <button
        className={!showPlayerStats ? "active tab" : "tab"}
        role="tab"
        aria-selected={!showPlayerStats}
        onClick={() => setShowPlayerStats(false)}
      ></button>

      <button
        className={showPlayerStats ? "active tab" : "tab"}
        role="tab"
        aria-selected={showPlayerStats}
        onClick={() => setShowPlayerStats(true)}
      ></button>
    </ToggleContainer>
  );
};

export default MobileTabList;
