import styled from "styled-components";
import { useExpertPicksQuery } from "../../hooks/Queries/useExpertPicksQuery";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useRoster } from "../../hooks/useRoster";
import { palette, pxToRem } from "../../styles/styleUtils";
import { getPlayerFromIdEntry } from "../../utils/Roster";
import { SwapContainer } from "../Roster/styles/PlayerSwap.styled";

const ExpertContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 45%;
  flex-flow: column;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
    margin-bottom: 25px;
  }

  .header {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 18px;
    }
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const BodySection = styled.div`
  display: flex;
  flex-flow: column;
  .entry {
    display: flex;
    justify-content: space-between;
  }
  .name,
  .picks,
  .bench {
    padding-top: 3px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d5d5d5;
  }
  .name {
    width: 135px;
    font-size: 24px;
    font-weight: 700;
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 16px;
      width: 90px;
    }
  }
  .picks {
    width: 260px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${breakpoints.mobile}) {
      width: 156px;
    }

    img {
      width: 61px;
      height: 59px;
      @media (max-width: ${breakpoints.mobile}) {
        height: 36px;
        width: 36px;
      }
    }
  }
  .bench {
    width: 131px;
    @media (max-width: ${breakpoints.mobile}) {
      width: 76px;
    }

    div {
      margin-left: 20px;
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 12px;
        margin-left: 0;
      }
    }
  }
`;

const ExpertPicks = () => {
  const { data: expertPicksData } = useExpertPicksQuery();
  const { round } = useRoster();

  return (
    <ExpertContainer>
      <HeaderSection>
        <div className="header">Expert Picks</div>
        <SwapContainer className="expert-roster">
          <div className="section-label">starters</div>
        </SwapContainer>
        <SwapContainer className="expert-bench">
          <div className="section-label">Bench</div>
        </SwapContainer>
      </HeaderSection>
      <BodySection>
        {expertPicksData?.entries?.map((entry) => {
          const benchCurrentPicks = entry?.current_picks?.picks[round - 1];
          const benchPlayerOne = getPlayerFromIdEntry(entry as any, benchCurrentPicks[`slot_5`]);
          const benchPlayerTwo = getPlayerFromIdEntry(entry as any, benchCurrentPicks[`slot_6`]);
          return (
            <div className="entry" key={entry.entry_id}>
              <div className="name">{entry.name}</div>
              <div className="picks">
                {entry?.current_picks?.picks?.map((slot, i) => {
                  if (i <= 3) {
                    const currentPicks = entry?.current_picks?.picks[round - 1];
                    const player = getPlayerFromIdEntry(entry as any, currentPicks[`slot_${i + 1}`]);
                    return (
                      <img
                        key={player.remote_id}
                        src={`https://pga-tour-res.cloudinary.com/image/upload/t_headshots_leaderboard_l/headshots_${player.remote_id}.png`}
                        alt="player headshot"
                      />
                    );
                  }
                })}
              </div>
              <div className="bench">
                <>
                  {benchCurrentPicks.slot_5 && (
                    <div>
                      {benchPlayerOne?.first_name || "TBD"} {benchPlayerOne?.last_name}
                    </div>
                  )}
                  {benchCurrentPicks.slot_6 && (
                    <div>
                      {benchPlayerTwo?.first_name || "TBD"} {benchPlayerTwo?.last_name}
                    </div>
                  )}
                </>
              </div>
            </div>
          );
        })}
      </BodySection>
    </ExpertContainer>
  );
};

export default ExpertPicks;
