import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { palette, pxToRem } from "../../styles/styleUtils";
import { IPlayer } from "../../types/interfaces/RosterTypes";

export interface StatProps {
  player: IPlayer;
}

const PlayerBio = ({ player }: StatProps) => {
  return (
    <LatestNewsContainer>
      <div className="rank-section">
        <div className="pga-header-container">
          <img src={`/images/pga-tour.png`} alt="PGA TOUR"></img>
          <div className="header">PGATOUR</div>
        </div>
        <div className="rank">
          <span>{player?.stats?.fedex_rank == 999999999 ? "N/A" : player?.stats?.fedex_rank}</span> <span>Rank</span>{" "}
          <span>FEDEX CUP</span>
        </div>
        <div className="sponsors">
          <img src={`/images/fedexcup.png`} alt="fedex cup"></img>
          <img src={`/images/comcast-logo.png`} alt="comcast"></img>
        </div>
      </div>
    </LatestNewsContainer>
  );
};

export default PlayerBio;

const LatestNewsContainer = styled.div`
  display: flex;
  width: 100%;
  .rank-section {
    width: 100%;
    position: relative;
    .header {
      font-size: ${pxToRem(26)};
      font-weight: 700;
      color: ${palette.blue};
      text-transform: uppercase;
      width: 100%;

      @media (max-width: ${breakpoints.tablet}) {
        font-size: 18px;
      }
    }
    .pga-header-container {
      display: flex;
      position: relative;
      padding-bottom: 24px;
      border-bottom: 1px solid ${palette.blue};
      img {
        height: 35px;
        width: 27px;
      }
    }
    .rank {
      border-radius: 50%;
      border: 1px solid ${palette.blue};
      height: 109px;
      width: 110px;
      background: white;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 43.5%;
      top: 20px;
      @media (max-width: ${breakpoints.tablet}) {
        right: 43%;
      }
      @media (max-width: ${breakpoints.mobile}) {
        height: 83px;
        width: 84px;
        right: 37.5%;
        top: 35px;
      }

      span:nth-child(1) {
        font-size: 57px;
        color: ${palette.blue};
        font-weight: 700;
        line-height: 44px;
        @media (max-width: ${breakpoints.tablet}) {
          font-size: 44px;
        }
      }

      span:nth-child(2) {
        font-weight: 700;
      }

      span:nth-child(2),
      span:nth-child(3) {
        font-size: 12px;
        text-transform: uppercase;
        @media (max-width: ${breakpoints.tablet}) {
          font-size: 9px;
        }
      }
    }
    .sponsors {
      display: flex;
      width: 100%;
      justify-content: space-between;
      img {
        height: 55px;
        width: 110px;

        @media (max-width: ${breakpoints.tablet}) {
          height: 45px;
          width: 85px;
        }
      }
    }
    .videos {
      .video-header {
        width: 100%;
        background-color: ${palette.blue};
        margin-bottom: 10px;
        .text {
          font-weight: 700;
          font-size: 18px;
          padding: 5px;
          color: white;
        }
      }
      .video-group {
        display: flex;
        justify-content: space-between;

        .video {
          width: 404px;
          height: 379px;
          background-color: grey;
          @media (max-width: ${breakpoints.tablet}) {
            width: 360px;
            height: 320px;
          }
          @media (max-width: ${breakpoints.mobile}) {
            width: 172px;
            height: 152px;
          }
        }
      }
    }
  }
`;
