import { useAuth } from "../../hooks/useAuth";
import { TrophyWrapper } from "./styles/TrophyDisplay.styled";
import Trophy from "./Trophy";

const TrophyDisplay = () => {
  const { user } = useAuth();
  return (
    <TrophyWrapper>
      <Trophy title={"Tournament"} value={user?.pgaroster?.entry?.tournament_rank_percentage} />
      <Trophy title={"Segment"} value={user?.pgaroster?.entry?.segment_rank_percentage} />
      <Trophy title={"Season"} value={user?.pgaroster?.entry?.season_rank_percentage} />
      <div className="label">OVERALL</div>
    </TrophyWrapper>
  );
};

export default TrophyDisplay;
