import { useRouter } from "next/dist/client/router";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../../styles/buttons.styled";
import { UserDetailsWrapper } from "./LandingAuth.styled";

const UserDetails = () => {
  const { user } = useAuth();
  const router = useRouter();
  return (
    <UserDetailsWrapper>
      <div className="entry-name">{`${user?.pgaroster?.entry?.name}'s picks`}</div>
      <Button onClick={() => router.push("/roster")}>EDIT ROSTER</Button>
    </UserDetailsWrapper>
  );
};

export default UserDetails;
