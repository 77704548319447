import styled from "styled-components";
import { useGameQuery, useTournamentQuery } from "../../hooks/Queries/useGame";
import { useInfinitePlayerList } from "../../hooks/Queries/useInfinitePlayerList";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useModal } from "../../hooks/useModal";
import { palette, pxToRem } from "../../styles/styleUtils";
import PctOrFptsHeadshots from "./PctOrFptsHeadshots";
import PctOrFptsModal from "./PctOrFptsModal";

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  .header {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 18px;
    }
    .info {
      border-radius: 100%;
      color: #757575;
      font-weight: bold;
      border: 1px solid #757575;
      width: 50px;
    }
  }

  .headshots {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;

    @media (max-width: ${breakpoints.mobile}) {
      flex-flow: column;
    }
  }

  img {
    height: 105px;
    width: 105px;

    @media (max-width: ${breakpoints.mobile}) {
      width: 70px;
      height: 70px;
    }
  }
`;

export const MoreButton = styled.button`
  font-size: 24px;
  font-weight: bold;
  color: ${palette.blue};
  text-transform: uppercase;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const PctOrFpts = ({ category }) => {
  const { data: gameData } = useGameQuery();
  const { data: tournyData } = useTournamentQuery();

  const tourneyHasStarted = tournyData?.started === true;

  const { data: playerList } = useInfinitePlayerList(
    tourneyHasStarted ? "field" : "all",
    "",
    category,
    tournyData?.tournament_id || -1,
  );
  const { handleModal } = useModal();

  return (
    <Container>
      <div className="header">{category == "pct" ? "Percent Ownership" : "Fantasy Leaderboard"}</div>
      <div className="subHeader">
        {category !== "pct"
          ? tourneyHasStarted
            ? gameData?.current_tournament?.name
            : "Season"
          : gameData?.current_tournament?.name}
      </div>
      <div className="headshots">
        {playerList?.pages[0]?.players?.slice(0, 5).map((player) => {
          return (
            <PctOrFptsHeadshots
              key={player.remote_id}
              player={player}
              category={category}
              modal={false}
              tournamentId={tournyData?.tournament_id || -1}
              tourneyHasStarted={tourneyHasStarted}
            />
          );
        })}
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <MoreButton
          onClick={() =>
            handleModal(
              <PctOrFptsModal
                category={category}
                tournamentId={tournyData?.tournament_id || -1}
                tourneyHasStarted={tourneyHasStarted}
              />,
            )
          }
        >
          More
        </MoreButton>
      </div>
    </Container>
  );
};

export default PctOrFpts;
