import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { palette } from "../../styles/styleUtils";

const PowerRankings = ({ power_rankings }) => {
  return (
    <Container>
      <h1 className="blue">power rankings: u.s. open</h1>
      <RankContainer>
        <RankRow className="header">
          <div className="rank">Rank</div>
          <div className="image"> (image)</div>
          <div className="player">Player</div>
          <div className="comment">Comment</div>
        </RankRow>
        <div className="body">
          {power_rankings?.map((golfer) => (
            <RankRow key={golfer.pid} className="row">
              <div className="rank">{golfer.rank}</div>
              <img
                className="image"
                src={`https://pga-tour-res.cloudinary.com/image/upload/t_headshots_leaderboard_l/headshots_${golfer.remote_pid}.png`}
                alt={`${golfer.name} headshot`}
              />
              <div className="player">{golfer.name}</div>
              <div className="comment">{golfer.comment}</div>
            </RankRow>
          ))}
        </div>
      </RankContainer>
    </Container>
  );
};
export default PowerRankings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 768px;
  max-height: 600px;
  overflow: auto;
  h1 {
    margin-bottom: 24px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 18px;
    }
  }
  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

const RankRow = styled.div`
  display: flex;
  max-width: 100%;
  height: 93px;
  align-items: center;
  padding: 0 47px 0 20px;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 5px 0 5px;
  }

  div {
    flex: 1 1 auto;
    width: 100%;
    &.rank {
      max-width: 50px;
      @media (max-width: ${breakpoints.mobile}) {
        max-width: 30px;
      }
    }

    &.player {
      max-width: 160px;
    }

    &.comment {
      flex: 2 1 auto;
      max-width: 392px;
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 14px;
        max-width: 100%;
        line-height: 13px;
        max-width: 47%;
      }
    }
  }

  img {
    max-width: 90px;
    width: 75px;
    height: 75px;
    margin-right: 10px;
    text-align: center;
    @media (max-width: ${breakpoints.mobile}) {
      max-width: 50px;
    }
  }
  &.header {
    background: linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(233, 233, 233) 100%);
    font-weight: bold;
    text-transform: uppercase;
    height: 49px;

    .image {
      visibility: hidden;
    }

    @media (max-width: ${breakpoints.mobile}) {
      .rank,
      .image {
        display: none;
      }
      .player {
        max-width: 55%;
      }
    }
  }
  &.body {
    overflow: auto;
  }

  &.row {
    border-bottom: 1px solid rgb(236, 236, 236);
    border-right: 1px solid rgb(236, 236, 236);
    border-left: 1px solid rgb(236, 236, 236);

    .rank {
      font-size: 50px;
      font-weight: 500;

      @media (max-width: ${breakpoints.mobile}) {
        font-size: 34px;
      }
    }

    .player {
      font-size: 21px;
      color: ${palette.blue};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: 16px;
        max-width: 102px;
      }
    }
  }
`;

const RankContainer = styled.div``;
