import styled from "styled-components";
import { Table } from "../../Table/Table";
import { palette, pxToRem } from "../../../styles/styleUtils";
import { breakpoints } from "../../../hooks/useBreakPoints";

export const StyledLeagueTable = styled(Table)`
  margin: 20px 0 0 0;
  p {
    margin-top: 0;
  }
  .search-row {
    justify-content: flex-end !important;
    margin-bottom: 0 !important;
    min-height: 45px !important;
  }
  .table-cell,
  .header-cell {
    justify-content: center;
    &:not(.header-cell) {
      font-size: 20px;
      font-weight: bold;
    }
    &.rank {
      flex: 0 0 100px;
      padding-left: 15.5px;
      &:not(.header-cell) {
        font-size: 38px;
        font-weight: bold;
        @media (max-width: ${breakpoints.mobile}) {
          font-size: 16px;
        }
      }
      @media (max-width: ${breakpoints.tablet}) {
        flex: 0 0 68px;
      }
      @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 12%;
      }
    }

    &.team {
      button {
        position: relative;
      }
      .email-tooltip {
        position: absolute;
        font-size: ${pxToRem(14)};
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
        opacity: 0;
      }
    }

    &.team,
    &.entry,
    &.email,
    &.points {
      flex: 0 0 200px;
      @media (max-width: ${breakpoints.tablet}) {
        flex: 0 0 150px;
      }
      @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 26%;
        font-size: 16px;
      }
    }
    @media (max-width: ${breakpoints.mobile}) {
      &.points {
        text-align: center;
      }
    }

    &.rank,
    &.points {
      text-align: center;
    }
    &.search,
    &.roster {
      flex: 1 0 auto;
      padding-right: 18px;
      @media (max-width: ${breakpoints.mobile}) {
        padding-right: 0;
        padding: 10px;
      }
    }
  }
  .search {
    .search-input {
      margin-left: auto;
      min-width: 281px;
      @media (max-width: ${breakpoints.tablet}) {
        min-width: 100%;
      }
      @media (max-width: ${breakpoints.mobile}) {
        min-width: 93%;
      }
    }
  }

  tr.header-row {
    background-color: ${palette.backgroundGrey};
    min-height: 67px;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: 0;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      .league-details {
        width: 200px;
      }
      .search {
        width: 150px;
        input {
          width: 150px;
          min-width: initial;
        }
      }
    }
    .header-cell:not(.search, .league-details) {
      text-transform: uppercase;
      color: ${palette.blue};
      font-weight: bold;
      font-size: ${pxToRem(12)};
    }
  }

  tr.league-header-row {
    display: flex;
    justify-content: space-between;
    .header-labels {
      display: flex;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      .header-sorters {
        display: flex;
      }
    }
    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column-reverse;
      padding: 10px 0 15px 0;
      .header-labels {
        width: 100%;
      }
      .header-sorters {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        .search {
          min-width: 100px;
        }
      }
    }
  }

  tbody {
    height: calc(100vh - 572px);
    min-height: 500px;
    overflow-y: auto;

    @media (max-width: ${breakpoints.mobile}) {
      height: calc(100vh - 480px);
    }
  }

  tr.row:not(.header-row) {
    min-height: 131px;
    border-bottom: 1px solid ${palette.borderGrey};
    .team {
      button {
        text-align: left;
      }
    }
    &.user-entry {
      background-color: ${palette.backgroundBlue};
    }
  }

  .row {
    display: flex;
    .row-entry-info {
      display: flex;
      flex: 0 0 520px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      .row-entry-info {
        flex: 0 0 319px;
      }
    }
    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      .row-entry-info {
        background: ${palette.backgroundGrey};
        padding: 10px 0;
        flex: 0;
        button {
          min-width: 110px !important;
          max-width: 110px !important;
        }
      }
      .bg-blue {
        background: #dee8f0;
      }
    }
  }
  .no-results {
    padding-top: 30px;
    font-size: 18px;
    text-align: center;
  }
  /* .row {
    padding: 0 13px 0 15.5px;
  } */
  .roster {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .pick {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      &.inactive {
        img {
          opacity: 0.5;
        }
      }
      .name {
        line-height: 1;
        min-height: 14px;
      }
      img {
        border-radius: 50%;
      }
    }
    .compare-btn {
      justify-self: flex-end;
    }
  }
`;
