import styled from "styled-components";
import { Table } from "../../Table/Table";
import { palette, pxToRem } from "../../../styles/styleUtils";
import { breakpoints } from "../../../hooks/useBreakPoints";

export const StyledLeagueListTable = styled(Table)`
  margin: 5px 0 0 0;
  .default-button {
    font-size: ${pxToRem(20)} !important;
    color: ${palette.blue};
    button {
      margin-top: 0 !important;
      position: relative;
      text-align: left !important;
    }
    &.selected {
      button::before {
        content: "★";
        position: absolute;
      }
    }
  }
  .table-cell,
  .header-cell {
    justify-content: center;
    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${pxToRem(16)};
    }
    &:not(.header-cell) {
      font-size: 20px;
      font-weight: bold;
    }
    &.access {
      flex: 0 0 100px;
      padding-left: 15.5px;
      &:not(.header-cell) {
        font-size: 38px;
        font-weight: bold;
      }
      @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 65px;
      }
    }

    &.league-name {
      button {
        margin-left: 0;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 200px;
        @media (max-width: ${breakpoints.mobile}) {
          width: 150px;
        }
      }
      color: ${palette.blue};
      @media (max-width: ${breakpoints.mobile}) {
        font-size: ${pxToRem(16)};
      }
    }
    &.league-name,
    &.members {
      flex: 0 0 200px;
      @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 150px;
      }
    }

    &.members {
      @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 50px;
        font-size: ${pxToRem(16)};
        text-align: center;
      }
    }
  }
  .search {
    .search-input {
      margin-left: auto;
      min-width: 281px;
      border: 1px solid ${palette.borderGrey};
      font-size: 14px;
      padding-left: 11px;
      &::placeholder {
        color: #b4b4b4;
      }
    }
  }

  tbody {
    max-height: 50vh;
    overflow-y: auto;
  }
  tr.header-row {
    min-height: 67px;
    align-items: center;
    border-bottom: 2px solid ${palette.black};
    .header-cell {
      color: ${palette.black};
      font-weight: 700;
    }
  }
  tr.row:not(.header-row) {
    min-height: 65px;
    svg {
      height: 25px;
      width: 30px;
      fill: #8e8e8e;
      padding-top: 2px;
    }
    border-bottom: 1px solid ${palette.borderGrey};
    &.user-entry {
      background-color: ${palette.backgroundBlue};
    }
  }
  /* .row {
    padding: 0 13px 0 15.5px;
  } */
  .join {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .join-btn {
      margin-left: auto;
      max-width: 56px;
    }
  }
`;
