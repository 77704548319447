import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useSnackbar } from "../../hooks/useSnackbar";
import { Button } from "../../styles/buttons.styled";
import { palette, pxToRem } from "../../styles/styleUtils";
import { IRolloverGroup } from "../../types/interfaces/authInterTypes";
import {
  activateRolloverLeague,
  optimisticActivateGroup,
  optimisticRetireGroup,
  retireRolloverLeague,
} from "../../utils/api/Leagues";

const RolloverWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  .rollover-title {
    font-size: ${pxToRem(26)};
    color: ${palette.blue};
    font-weight: 700;
    margin: 22px 0 16px 0;
  }
  .rollover-description {
    font-weight: 500;
  }
  .rollover-leagues {
    margin-top: 15px;
  }

  .rollover-league {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 15px;
    @media (max-width: ${breakpoints.mobile}) {
      align-items: flex-start;
    }
    &__name {
      font-weight: 700;
      font-size: ${pxToRem(20)};
    }
    &__button {
      display: flex;
      button {
        margin: 0 5px;
      }

      @media (max-width: ${breakpoints.mobile}) {
        flex-direction: column;
        button {
          margin: 5px 0;
        }
      }
    }
    button {
      padding: 10px 25px;
    }
  }
`;

let activatingGroup: IRolloverGroup | null = null;

const RolloverList = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const { addAlert } = useSnackbar();

  const { mutate: activateMutate } = useMutation(activateRolloverLeague, {
    onSuccess: (data) => {
      optimisticActivateGroup(queryClient, data);
      setIsActivating(false);
      addAlert(`You activated ${activatingGroup?.name}!`);
      activatingGroup = null;
      //invalidate query was firing off too fast and returning stale data
      //optimisticJoinGroup(queryClient, group);
    },
    onError: () => {
      addAlert(`Error activating ${activatingGroup?.name}`);
      setIsActivating(false);
      activatingGroup = null;
    },
  });

  const { mutate: retireMutate } = useMutation(retireRolloverLeague, {
    onSuccess: () => {
      optimisticRetireGroup(queryClient, activatingGroup);
      setIsActivating(false);
      addAlert(`You retired ${activatingGroup?.name}`);
      activatingGroup = null;
      //invalidate query was firing off too fast and returning stale data
      //optimisticJoinGroup(queryClient, group);
    },
    onError: () => {
      addAlert(`Error retiring ${activatingGroup?.name}`);
      setIsActivating(false);
      activatingGroup = null;
    },
  });

  const handleActivateClick = (group: IRolloverGroup) => {
    activatingGroup = group;
    activateMutate({ rollover_group_id: group?.rollover_group_id, entry_id: user?.pgaroster?.entry?.entry_id });
  };

  const handleRetireClick = (group: IRolloverGroup) => {
    activatingGroup = group;
    retireMutate({ rollover_group_id: group?.rollover_group_id, entry_id: user?.pgaroster?.entry?.entry_id });
  };

  return (
    <RolloverWrapper>
      <div className="rollover-title">Activate My Leagues</div>
      <div className="rollover-description">
        {`Welcome to the new version of PGA Tour Fantasy. We see that you are the creator of the following league(s)`}
        <br />
        {`Click "Activate" to create the league and invite all league members`}
      </div>
      <div className="rollover-leagues">
        {user?.pgaroster?.rollover_groups?.map((group, i) => {
          return (
            <div key={i} className="rollover-league">
              <div className="rollover-league__name">{group?.name}</div>
              <div className="rollover-league__button">
                <Button disabled={isActivating} onClick={() => handleActivateClick(group)}>
                  {isActivating ? "ACTIVATING" : "ACTIVATE"}
                </Button>
                <Button disabled={isActivating} onClick={() => handleRetireClick(group)}>
                  {isActivating ? "RETIRING" : "RETIRE"}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </RolloverWrapper>
  );
};

export default RolloverList;
