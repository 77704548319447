import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { palette, pxToRem } from "../../styles/styleUtils";

export const AuthContainer = styled.div``;

export const UserDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  .entry-name {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    @media (max-width: ${breakpoints.rosterMobile}) {
      font-size: ${pxToRem(18)};
    }
  }
`;

export const PicksContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  @media (max-width: ${breakpoints.rosterMobile}) {
    flex-wrap: wrap;
  }
  .picks-container {
    &__picks {
      width: 700px;
      @media (max-width: ${breakpoints.rosterMobile}) {
        width: 100%;
        margin-top: 35px;
      }
    }
    &__details {
      margin-left: 54px;
      flex-grow: 1;
      @media (max-width: ${breakpoints.rosterMobile}) {
        width: 100%;
        margin: 25px 0;
      }
    }
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin-top: 63px;
  justify-content: space-between;

  @media (max-width: ${breakpoints.tablet}) {
    align-items: center;
  }
  @media (max-width: ${breakpoints.laptop}) {
    flex-flow: column;
  }
`;
