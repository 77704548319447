import PctOrFptsHeadshots from "./PctOrFptsHeadshots";
import styled from "styled-components";
import { palette, pxToRem } from "../../styles/styleUtils";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useInfinitePlayerList } from "../../hooks/Queries/useInfinitePlayerList";
import { TOrder } from "../../types/interfaces/RosterTypes";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 36px;
  overflow: scroll;
  height: 650px;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 20px;
  }

  .header {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 72px;
    display: flex;
    justify-content: space-between;
    height: 40px;
    input {
      height: 35px;
    }
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 18px;
      flex-flow: column;
      height: 100px;
      margin-bottom: 25px;
    }
  }

  img {
    width: 81px;
    height: 81px;

    @media (max-width: ${breakpoints.mobile}) {
      height: 59px;
      width: 59px;
    }
  }
`;

interface PctOrFptsModalProps {
  category: TOrder;
  tournamentId: number;
  tourneyHasStarted: boolean;
}

const PctOrFptsModal = ({ category, tournamentId, tourneyHasStarted }: PctOrFptsModalProps) => {
  const [search, setSearch] = useState("");

  const {
    data: playerList,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfinitePlayerList("field", search, category, tournamentId);

  const handleScroll = (e) => {
    const isFetchPoint = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 20;
    isFetchPoint && fetchNextPage();
  };

  const handleSearch = (e) => {
    if (!e.target) {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  };

  // Debounce search input and refetch with param on debounce
  const debouncedSearchHandler = useMemo(() => debounce(() => refetch(), 300), []);
  useEffect(() => {
    debouncedSearchHandler();
    return () => {
      debouncedSearchHandler.cancel();
    };
  }, [search, debouncedSearchHandler]);

  const pageText =
    (!hasNextPage && !search) || (!hasNextPage && search && playerList?.pages[0].players.length != 0)
      ? "No More Players to Load.."
      : !hasNextPage && search && playerList?.pages[0].players.length == 0
      ? "Can't Find That Player, Please Try Again.."
      : "Loading More Players...";

  return (
    <Container
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className="header">
        <div>{category == "pct" ? "Percent Ownership" : "Fantasy Leaderboard"}</div>
        <input
          className="search"
          placeholder="Search Player.."
          onChange={(e) => handleSearch(e.target.value)}
          value={search}
        />
      </div>
      {playerList?.pages.map((page) => {
        return page.players.map((player) => {
          return (
            <PctOrFptsHeadshots
              placement={player?.stats?.rank}
              alignListToTop={playerList.pages[0].players.length <= 3}
              key={player.remote_id}
              player={player}
              category={category}
              modal={true}
              tournamentId={tournamentId}
              tourneyHasStarted={tourneyHasStarted}
            />
          );
        });
      })}

      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
        {pageText}
      </div>
    </Container>
  );
};

export default PctOrFptsModal;
