import { useTournamentsQuery } from "../../hooks/Queries/useGame";
import { ITournamentHistoricalDetails } from "../../types/interfaces/RosterTypes";
import { Cell, HeaderCell, Row } from "../Table/Table";
import { StatsTable, YtdContainer } from "./YtdStats";

interface YTDResultsProps {
  tournament_player_data: ITournamentHistoricalDetails[];
}

const YtdResults = ({ tournament_player_data }: YTDResultsProps) => {
  // 📝 ToDo: Fix any type
  const { data }: any = useTournamentsQuery();
  const currentSeasonData: ITournamentHistoricalDetails[] = [];

  const findTournamentName = (tournament_id) => {
    const tournament = data?.tournaments?.find((tournament) => tournament.tournament_id == tournament_id);
    return tournament?.name;
  };

  const currentSeasonPlayerData = () => {
    for (let i = 0; i < tournament_player_data?.length; i++) {
      for (let e = 0; e < data?.tournaments?.length; e++) {
        if (tournament_player_data[i].tournament_id == data?.tournaments[e]?.tournament_id) {
          currentSeasonData.push(tournament_player_data[i]);
        }
      }
    }
    return currentSeasonData;
  };

  currentSeasonPlayerData();

  return (
    <YtdContainer style={{ flexFlow: "column" }}>
      <div className="header">YTD Results</div>
      <StatsTable>
        <thead>
          <Row className="header-row">
            <HeaderCell>Tournament</HeaderCell>
            <HeaderCell>Pos</HeaderCell>
            <HeaderCell>To Par</HeaderCell>
            <HeaderCell>FedExCup Points</HeaderCell>
            <HeaderCell>FedExCup Bonus</HeaderCell>
          </Row>
        </thead>
        <tbody>
          {currentSeasonData.map((tournament) => {
            return (
              <Row key={tournament.tournament_id}>
                <Cell>{findTournamentName(tournament.tournament_id)}</Cell>
                <Cell>{tournament?.position || "N/A"}</Cell>
                <Cell>
                  {Math.sign(Number(tournament?.total_par_relative_score)) == 1
                    ? `+${tournament?.total_par_relative_score}`
                    : tournament?.total_par_relative_score == null
                    ? "N/A"
                    : Number(tournament?.total_par_relative_score) === 0
                    ? "E"
                    : tournament?.total_par_relative_score}
                </Cell>
                <Cell>{tournament?.raw_fedex_cup_points}</Cell>
                <Cell>{tournament?.fedex_points}</Cell>
              </Row>
            );
          })}
        </tbody>
      </StatsTable>
    </YtdContainer>
  );
};

export default YtdResults;
