import settings from "../../settings";
import { ISession } from "../../types/interfaces/authInterTypes";

const pathToPageName: any = {
  "/roster": "roster",
  "/leagues": "leagues",
  "/leaderboard": "leaderboard",
  "/": "landing",
};

declare global {
  interface Window {
    s: any;
    digitalData: any;
    _satellite: any;
    analyticsInfo: AnalyticsInfo;
    ssoCheckFinished: boolean;
    s_gi: (rsid: string) => any;
    Visitor: any;
    adobe: any;
    dataLayer: any[];
    OneTrust: any;
    OptanonActiveGroups: string;
  }
}

export interface IAdobePageView {
  gameData?: GameData;
}

export interface GameData {
  gid?: string | null;
  user?: ISession | null;
}

interface AnalyticsInfo {
  isConfigured: boolean;
}

type DigitalData = {
  deviceType: string;
  event: string;
  pageName: string;
  pageURL: string;
  siteSection: string;
  siteSubSection: string;
  siteSubSection2: string;
  tourName: string;
  tournamentID: string;
  ipAddress: string;
  gigyaID: number | undefined;
  userLoggedIn: boolean;
  playerIDs: string;
};

let digitalData: DigitalData = {
  deviceType: "",
  event: "",
  pageName: "",
  pageURL: "",
  siteSection: "",
  siteSubSection: "",
  siteSubSection2: "",
  tourName: "",
  tournamentID: "",
  ipAddress: "",
  gigyaID: undefined,
  userLoggedIn: false,
  playerIDs: "",
};

let pageBase = "pgatour:fantasygame";

// no longer using adobe launch for analytics
// function oneTrustInit() {
//   if (typeof window !== "undefined") {
//     window.adobeDataLayer = window.adobeDataLayer || [];
//     window.adobeDataLayer.push({
//       event: "LaunchOTLoaded",
//       OnetrustActiveGroups: window.OptanonActiveGroups?.toString(),
//     });
//     if (window.adobeDataLayer[0]?.event !== "LaunchOTLoaded") {
//       window.adobeDataLayer.splice(0, 1);
//     }
//   }
// }

function updatePageData() {
  pageBase =
    "pgatour:fantasygame:" +
    (settings.ENVIRONMENT.includes("production") ? "roster:" : settings.ENVIRONMENT + "roster:");
}

function firePageView(user, tournamentID) {
  if (typeof window !== "undefined") {
    console.warn(" *** OMNITURE PAGE *** ");

    updatePageData();
    if (typeof digitalData === "undefined" || digitalData === null) {
      digitalData = {
        deviceType: "",
        event: "",
        pageName: "",
        pageURL: "",
        siteSection: "",
        siteSubSection: "",
        siteSubSection2: "",
        tourName: "",
        tournamentID: "",
        ipAddress: "",
        gigyaID: undefined,
        userLoggedIn: false,
        playerIDs: "",
      };
    }

    digitalData.event = "pageLoaded";
    digitalData.deviceType = window?.isCordova ? "native" : "web";
    digitalData.tourName = "pgatour";
    digitalData.tournamentID = tournamentID;

    if (pathToPageName[window.location.pathname]) {
      digitalData.pageName = pageBase + pathToPageName[window.location.pathname];
    } else if (window.location.pathname.includes("leagues/")) {
      digitalData.pageName = pageBase + "leagues";
    } else {
      digitalData.pageName = pageBase;
    }
    digitalData.siteSection = "Fantasy Game";
    digitalData.pageURL = window.location.pathname;

    if (user) {
      digitalData.userLoggedIn = !!user && !!user.user_id;
      if (user?.user_id) {
        digitalData.gigyaID = user?.user_id;
      }
    }
    window.digitalData = digitalData;
  }
}

const Omniture = {
  firePageView,
  updatePageData,
  // oneTrustInit,
};

export default Omniture;
