import styled from "styled-components";
import { palette, pxToRem } from "../../../styles/styleUtils";

export const TrophyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  .label {
    position: absolute;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    background: #fff;
    padding: 5px 10px;
    z-index: 0;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 25px;
    border-color: #d5d5d5;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    position: absolute;
    bottom: -25px;
    z-index: -1;
  }
`;

export const TrophyColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 84px;
    height: 84px;
  }
  .trophy-column {
    &__title {
      color: ${palette.blue};
      font-size: ${pxToRem(21)};
      font-weight: 700;
    }
  }
`;
