import { SwapContainer } from "../Roster/styles/PlayerSwap.styled";
import { UNPICKED, slots } from "../../constants/Roster";
import LandingRosterPlayer from "./LandingRosterPlayer";
import { useRoster } from "../../hooks/useRoster";
import { getLiveRound, getPlayerFromIdEntry } from "../../utils/Roster";
import { useState } from "react";
import ScoreCard from "../Roster/RosterSelect/ScoreCard/Scorecard";
import { useEffect } from "react";
import { useGameQuery, useMyEntryQuery, useTournamentQuery } from "../../hooks/Queries/useGame";
import { useSessionQuery } from "../../hooks/Queries/useSessionQuery";
import SlideOpen from "../Animations/SlideOpen";
import { useBreakpoints } from "../../hooks/useBreakPoints";
import styled from "styled-components";

const LandingRosterWrapper = styled.div`
  .close-button {
    width: 100%;
    background: #b8b8b8;
    color: #fff;
    font-weight: 700;
    margin-top: 30px;
    p {
      margin: 0;
      padding: 6px;
      position: relative;
      display: inline-block;
      &:before,
      &:after {
        content: "";
        height: 50%;
        width: 2px;
        background: #fff;
        display: block;
        position: absolute;
        top: 44%;
      }
      &:before {
        left: -1px;
        transform: rotate(-45deg) translateY(-50%);
      }
      &:after {
        left: -14px;
        transform: rotate(45deg) translateY(-50%);
      }
    }
  }
`;

const LandingRoster = () => {
  const { data: tournamentData } = useTournamentQuery();

  const [canOpen, setCanOpen] = useState<boolean>(false);
  const [currentTournamentSelected, setCurrentTournamentSelected] = useState<boolean>(true);
  const { round, selectedPlayerId, setSelectedPlayerId, selectedTournamentId, setRound } = useRoster();

  const { data: user, isLoading } = useSessionQuery();
  const { data: gameData } = useGameQuery();
  const { data: entryData } = useMyEntryQuery();

  const { isRosterMobile } = useBreakpoints();

  const [activeSlot, setActiveSlot] = useState<number | null>(!isRosterMobile ? 0 : null);

  useEffect(() => {
    if (selectedTournamentId && gameData?.current_tournament?.tournament_id) {
      setCurrentTournamentSelected(gameData?.current_tournament?.tournament_id === selectedTournamentId);
    }
  }, [selectedTournamentId]);

  useEffect(() => {
    if (tournamentData?.rounds) {
      const roundIndex = getLiveRound(tournamentData?.rounds);
      setRound(roundIndex + 1);
    }
  }, [tournamentData]);

  const entry =
    entryData && selectedTournamentId && selectedTournamentId !== gameData?.current_tournament?.tournament_id
      ? entryData
      : user?.pgaroster?.entry && currentTournamentSelected
      ? user?.pgaroster?.entry
      : entryData;

  const currentPicks = entry?.current_picks?.picks[round - 1];

  const handleSlotClick = (slot: number, playerId: number) => {
    setActiveSlot(slot === activeSlot ? null : slot);
    setSelectedPlayerId(playerId === selectedPlayerId ? null : playerId);
  };

  useEffect(() => {
    setCanOpen(true);
    if (!isLoading && !isRosterMobile) {
      const playerId = currentPicks && currentPicks["slot_1"];
      playerId && setSelectedPlayerId(playerId);
    } else {
      setSelectedPlayerId(null);
    }
  }, []);
  return (
    <LandingRosterWrapper>
      <SwapContainer>
        {slots.map((slot, i) => {
          const playerId = currentPicks && currentPicks[slot];
          if (i <= 3) {
            return (
              <LandingRosterPlayer
                key={i}
                isActive={activeSlot === i}
                pick={playerId ? getPlayerFromIdEntry(entry, playerId) : UNPICKED}
                slotIndex={i}
                onClick={handleSlotClick}
              />
            );
          }
        })}
      </SwapContainer>
      <SlideOpen isOpen={selectedPlayerId !== null && canOpen} height={"390px"}>
        <>
          <button
            onClick={() => {
              setSelectedPlayerId(null);
              setActiveSlot(null);
            }}
            className="close-button"
          >
            <p>Close</p>
          </button>

          <ScoreCard isLanding={true} />
        </>
      </SlideOpen>
    </LandingRosterWrapper>
  );
};

export default LandingRoster;
