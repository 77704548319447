import { useState } from "react";
import { useEffect } from "react";
import { adIds } from "../../constants/Ads";
import { useGameQuery } from "../../hooks/Queries/useGame";
import { useAuth } from "../../hooks/useAuth";
import { useBreakpoints } from "../../hooks/useBreakPoints";
import { cordovaCheck } from "../../utils/Cordova";
import Ad from "../Ad";
import ScrolledAd from "../ScrolledAd";
import Editorials from "./Editorials";
import { InfoRow } from "./LandingAuth.styled";
import { NoAuthContainer, RegisterContainer, RegisterButton } from "./LandingNoAuth.styled";
import PowerRankings from "./PowerRankings";
import SleeperPicks from "./SleeperPicks";

export default function LandingNoAuth(): JSX.Element {
  const { login } = useAuth();
  const { isMobileAds, isTabletAds } = useBreakpoints();
  const [isCordova, setIsCordova] = useState<boolean>(true);
  const { data: gameData } = useGameQuery();

  useEffect(() => {
    setIsCordova(cordovaCheck());
  }, [typeof window]);

  return (
    <NoAuthContainer>
      <RegisterContainer>
        <div className="register-header">PGA Tour Fantasy Golf</div>
        <div className="placeholder">
          <div className="image-wrapper">
            <div>
              <img src={`/images/updated-logged-out-hero.png`} alt="PGA Tour Roster Hero"></img>
            </div>
          </div>
          <div className="lower-banner">
            <div className="lower-banner-text">Select your lineup of 4 starters and two bench players to enter.</div>
          </div>
        </div>
        <div className="buttons-container">
          <RegisterButton onClick={() => login()}>Register</RegisterButton>
          <RegisterButton onClick={() => login()}>Log In</RegisterButton>
        </div>
      </RegisterContainer>

      {!isMobileAds && !isCordova && (
        <ScrolledAd>
          <Ad adId={isTabletAds ? adIds.TABLET_LANDING_NOT_LOGGED : adIds.DESKTOP_LANDING_NOT_LOGGED} pos={"middle"} />
        </ScrolledAd>
      )}
      <InfoRow>
        {gameData?.current_tournament?.power_ranking && (
          <PowerRankings power_rankings={gameData?.current_tournament?.power_ranking} />
        )}
        {gameData?.current_tournament?.sleeper_pick && (
          <SleeperPicks sleeper_pick={gameData?.current_tournament?.sleeper_pick} />
        )}
      </InfoRow>
      <Editorials />
    </NoAuthContainer>
  );
}
