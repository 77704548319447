import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { useModal } from "../../hooks/useModal";
import { palette } from "../../styles/styleUtils";
import { IPlayer } from "../../types/interfaces/RosterTypes";
import PlayerDetails from "./PlayerDetailsModal";

const HeadshotContainer = styled.div<{ modal: boolean; alignListToTop: boolean }>`
  display: flex;
  position: relative;
  flex-basis: 33.33%;
  align-items: ${({ alignListToTop }) => (alignListToTop ? "flex-start" : "center")};
  height: ${({ alignListToTop }) => (alignListToTop ? "65%" : "15%")};
  cursor: pointer;

  .player-details {
    display: flex;
    align-items: center;
    flex-flow: ${({ modal }) => (modal ? "row" : "column")};
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-basis: 50%;
    flex-flow: row;
  }
`;

const RankTag = styled.div<{ modal: boolean }>`
  position: absolute;
  left: ${({ modal }) => (modal ? "59px" : "77px")};
  top: 0;
  background-color: ${palette.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  @media (max-width: ${breakpoints.mobile}) {
    height: 31px;
    width: 31px;
    left: ${({ modal }) => (modal ? "44px" : "51px")};
    font-size: 16px;
  }
`;

export const CircleProgressBar = styled.div<{ angle: number; modal: boolean }>`
  background: radial-gradient(white 50%, transparent 51%),
    conic-gradient(transparent 0deg ${({ angle }) => angle}deg, #d5d5d5 ${({ angle }) => angle}deg 360deg),
    conic-gradient(#ff7575 0deg, #ff7575 90deg, #ff7575 180deg, #ff7575);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  letter-spacing: 0.04px;
  font-size: 13px;
  background-color: white;
  position: absolute;
  left: ${({ modal }) => (modal ? "59px" : "77px")};
  top: 0;

  @media (max-width: ${breakpoints.mobile}) {
    height: 31px;
    width: 31px;
    left: ${({ modal }) => (modal ? "44px" : "51px")};
  }
`;

export const PlayerName = styled.div<{ modal: boolean }>`
  display: flex;
  justify-content: center;
  margin-left: ${({ modal }) => (modal ? "20px" : "0")};
  @media (max-width: ${breakpoints.mobile}) {
    margin-left: 20px;
    flex-flow: column;
  }
`;
interface HeadShotsProps {
  player?: IPlayer | null;
  category: string;
  modal: boolean;
  tournamentId: number;
  alignListToTop?: boolean;
  placement?: number | null | undefined;
  tourneyHasStarted?: boolean;
}

const PctOrFptsHeadshots = ({
  player,
  category,
  modal,
  alignListToTop = false,
  placement,
  tournamentId,
  tourneyHasStarted,
}: HeadShotsProps) => {
  const { handleModal } = useModal();

  const playersCurrentTournamentData =
    player && player.tournament_players && player?.tournament_players.find((p) => p.tournament_id === tournamentId);

  return (
    <HeadshotContainer
      modal={modal}
      key={player?.remote_id}
      alignListToTop={alignListToTop}
      onClick={() => {
        handleModal(<PlayerDetails player={player} />);
      }}
    >
      {category == "fpts" && modal && <div style={{ height: "100%" }}>{placement}.</div>}
      <div className="player-details">
        <div style={{ position: "relative" }}>
          <img
            src={`https://pga-tour-res.cloudinary.com/image/upload/t_headshots_leaderboard_l/headshots_${player?.remote_id}.png`}
            alt={`${player?.first_name} ${player?.last_name} headshot`}
          />
          {category == "pct" ? (
            <CircleProgressBar angle={player?.stats?.pct == null ? 0 : player.stats?.pct * 3.6} modal={modal}>
              {player?.stats?.pct}
              <span style={{ fontSize: "6px" }}>%</span>
            </CircleProgressBar>
          ) : (
            <>
              {tourneyHasStarted ? (
                <RankTag modal={modal}>{playersCurrentTournamentData?.total_fantasy_points}</RankTag>
              ) : (
                <RankTag modal={modal}>{player?.stats?.fpts}</RankTag>
              )}
            </>
          )}
        </div>
        <PlayerName modal={modal}>
          <span>{player?.first_name.charAt(0)}.</span> <span>{player?.last_name}</span>
        </PlayerName>
      </div>
    </HeadshotContainer>
  );
};

export default PctOrFptsHeadshots;
