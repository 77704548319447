import { StyledLeagueTable } from "../Leagues/styles/LeagueTable.styled";
import styled from "styled-components";
import { Cell, HeaderCell, Row } from "../Table/Table";
import { palette, pxToRem } from "../../styles/styleUtils";
import { breakpoints } from "../../hooks/useBreakPoints";
import { StatProps } from "./PlayerBio";

const YtdStats = ({ player }: StatProps) => {
  const ytd_stats = [
    {
      label: "SG: Off the Tee",
      value: player?.stats?.sg_off_the_tee,
      rank: player?.stats?.sg_off_the_tee_rank,
    },
    {
      label: "SG: Approach the Green",
      value: player?.stats?.sg_approach_the_green,
      rank: player?.stats?.sg_approach_the_green_rank,
    },
    {
      label: "SG: Around the Green",
      value: player?.stats?.sg_around_the_green,
      rank: player?.stats?.sg_around_the_green_rank,
    },
    {
      label: "SG: Putting",
      value: player?.stats?.sg_putting,
      rank: player?.stats?.sg_putting_rank,
    },
    {
      label: "SG: Tee to Green",
      value: player?.stats?.sg_tee_to_green,
      rank: player?.stats?.sg_tee_to_green_rank,
    },
    {
      label: "SG: Total",
      value: player?.stats?.sg_total,
      rank: player?.stats?.sg_total_rank,
    },
    {
      label: "Scoring Average",
      value: player?.stats?.scoring_avg,
      rank: player?.stats?.scoring_avg_rank,
    },
    {
      label: "Driving Distance",
      value: player?.stats?.driving_distance,
      rank: player?.stats?.driving_distance_rank,
    },
    {
      label: "Driving Accuracy %",
      value: player?.stats?.driving_accuracy_pct,
      rank: player?.stats?.driving_accuracy_pct_rank,
    },
    {
      label: "Greens in Regulation %",
      value: player?.stats?.greens_in_regulation_pct,
      rank: player?.stats?.greens_in_regulation_pct_rank,
    },
    {
      label: "Scrambling",
      value: player?.stats?.scrambling,
      rank: player?.stats?.scrambling_rank,
    },
  ];

  const peformance_stats = [
    {
      label: "Wins",
      ytd: player?.stats?.ytd_wins,
      career: player?.stats?.career_wins,
    },
    {
      label: "Top 10 Finishes",
      ytd: player?.stats?.top10,
      career: player?.stats?.career_top_10_finishes,
    },
    {
      label: "Events Played",
      ytd: player?.stats?.ytd_events_played,
      career: player?.stats?.career_events_played,
    },
    {
      label: "Official Money",
      ytd: player?.stats?.ytd_money,
      career: player?.stats?.career_money,
    },
  ];
  return (
    <YtdContainer>
      <TableContainer>
        <div className="header">YTD STATS</div>
        <StatsTable>
          <thead>
            <Row className="header-row">
              <th style={{ visibility: "hidden", flex: "3" }}></th>
              <HeaderCell className="league-name">Value</HeaderCell>
              <HeaderCell className="members">Rank</HeaderCell>
            </Row>
          </thead>
          <tbody>
            {ytd_stats?.map((stat, index) => {
              return (
                <Row key={index}>
                  <Cell>{stat.label}</Cell>
                  <Cell>{stat.value || "N/A"}</Cell>
                  <Cell>{stat.rank || "N/A"}</Cell>
                </Row>
              );
            })}
          </tbody>
        </StatsTable>
      </TableContainer>
      <TableContainer>
        <div className="header">PERFORMANCE</div>
        <StatsTable>
          <thead>
            <Row className="header-row">
              <th style={{ visibility: "hidden", flex: "3" }}></th>
              <HeaderCell className="league-name">Ytd</HeaderCell>
              <HeaderCell className="members">Career</HeaderCell>
            </Row>
          </thead>
          <tbody>
            {peformance_stats?.map((stat, index) => {
              return (
                <Row key={index}>
                  <Cell>{stat.label}</Cell>
                  <Cell>{stat.ytd || "N/A"}</Cell>
                  <Cell>{stat.career || "N/A"}</Cell>
                </Row>
              );
            })}
          </tbody>
        </StatsTable>
      </TableContainer>
    </YtdContainer>
  );
};

export default YtdStats;

export const YtdContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.mobile}) {
    flex-flow: column;
  }

  .header {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 18px;
    }
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 49%;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const StatsTable = styled(StyledLeagueTable)`
  .row {
    @media (max-width: ${breakpoints.mobile}) {
      flex-flow: row !important;
    }
  }
  thead {
    tr {
      min-height: 35px !important;
      margin-bottom: 0 !important;
      th:not(:first-child) {
        text-align: center;
      }
      th:nth-child(1) {
        flex: 3;
        padding-left: 10px;

        @media (max-width: ${breakpoints.mobile}) {
          flex: 2 1 80% !important;
        }
      }
    }
  }
  tbody {
    min-height: 400px !important;
    tr {
      min-height: 20px !important;

      td {
        font-size: 16px !important;
        font-weight: normal !important;
        padding: 5px 0;
      }
      td:nth-child(1) {
        flex: 3;
        padding-left: 10px;
        @media (max-width: ${breakpoints.mobile}) {
          flex: 2 1 80%;
        }
      }
      td:not(:first-child) {
        text-align: center;
      }
    }
  }
`;
