import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { Button } from "../../styles/buttons.styled";
import { palette, pxToRem } from "../../styles/styleUtils";

export const NoAuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${breakpoints.tablet}) {
      flex-wrap: wrap;
      button {
        margin: 5px;
        width: 100%;
      }
    }
  }
  &:before {
    content: "";
    background-color: ${palette.backgroundGrey};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 600px;
    z-index: -1;
    @media (max-width: ${breakpoints.tablet}) {
      height: 570px;
    }
    @media (max-width: ${breakpoints.mobile}) {
      height: 650px;
    }
  }

  .register-header {
    color: ${palette.blue};
    font-size: ${pxToRem(27)};
    font-weight: bold;
    text-transform: uppercase;
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 31px;
    background-color: #b3daff;
    position: relative;
    @media (max-width: ${breakpoints.tablet}) {
      margin: 31px -20px 0;
    }
    .image-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 384px;
      @media (max-width: ${breakpoints.tablet}) {
        min-height: 384px;
      }
      & > div {
        display: block;
        overflow: hidden;
        position: absolute;
        inset: 0px;
        box-sizing: border-box;
        margin: 0px;
        img {
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
          object-fit: cover;
          position: absolute;
          inset: 0px;
          box-sizing: border-box;
          padding: 0px;
          border: none;
          margin: auto;
          display: block;
          width: 0px;
          height: 0px;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
        }
      }
    }
    .placeholder-text {
      color: white;
      font-weight: bold;
      font-size: 20px;
      opacity: 0.6;
    }
    .lower-banner {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 72px;
      bottom: 0;
      background-color: white;
      opacity: 0.8;
      .lower-banner-text {
        margin-left: 31px;
        font-size: ${pxToRem(18)};
      }
    }
  }
`;

export const RegisterButton = styled(Button)`
  margin: 31px 10px 44px;
  padding: 20px 0;
  min-width: 261px;
  height: 65px;
  font-size: ${pxToRem(21)};
  @media (max-width: ${breakpoints.tablet}) {
    margin: 10px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin-top: 63px;
`;
