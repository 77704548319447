import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";

import LandingNoAuth from "../components/Landing/LandingNoAuth";
import cookies from "next-cookies";
import { API_COOKIE_NAME } from "../utils/auth";
import LandingAuth from "../components/Landing/LandingAuth";
import { useEffect } from "react";
import Omniture from "../utils/analytics/omniture";
import { useTournamentQuery } from "../hooks/Queries/useGame";
const LoadingContainer = styled.div`
  min-height: 490px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default function Index(): JSX.Element {
  // if (typeof window !== "undefined") {
  //   console.log(window.gigya.accounts, "window");
  const { isAuthenticated, user, isLoading } = useAuth();
  const { data: tournyData } = useTournamentQuery();
  useEffect(() => {
    if (window.OptanonActiveGroups && window.OptanonActiveGroups.indexOf("C0004") > -1) {
      Omniture.firePageView(isAuthenticated ? user : "", tournyData ? tournyData?.tournament_id : "");
    }
  }, []);
  return <>{isLoading ? <LoadingContainer /> : isAuthenticated ? <LandingAuth /> : <LandingNoAuth />}</>;
}

export async function getServerSideProps(ctx) {
  // Fetch data from external API
  const allCookies = cookies(ctx);
  const AuthCookie = allCookies[API_COOKIE_NAME] || null;

  // Pass data to the page via props
  return { props: { cookies: AuthCookie } };
}
