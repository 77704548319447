export default function TrophyIcon(props: any): JSX.Element {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.000000 150.000000" {...props}>
      <g
        transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)"
        fill={`${props.color ? props.color : "#000"}`}
        stroke="none"
      >
        <path
          d="M410 1270 c0 -29 -1 -30 -50 -30 -45 0 -50 -2 -56 -26 -11 -44 6
-156 33 -216 29 -66 75 -111 216 -213 l97 -70 0 -60 c0 -66 -23 -128 -59 -159
-14 -13 -41 -21 -79 -24 -31 -3 -60 -10 -64 -16 -9 -13 -11 -218 -2 -240 5
-14 42 -16 304 -16 262 0 299 2 304 16 9 22 7 227 -2 240 -4 6 -33 13 -64 16
-38 3 -65 11 -79 24 -36 31 -59 93 -59 159 l0 60 97 70 c141 102 187 147 216
213 27 60 44 172 33 216 -6 24 -11 26 -56 26 -49 0 -50 1 -50 30 l0 30 -340 0
-340 0 0 -30z m640 -82 c-1 -148 -44 -274 -123 -357 -54 -56 -108 -81 -177
-81 -171 0 -299 188 -300 438 l0 72 300 0 300 0 0 -72z m-639 -50 c0 -55 19
-148 43 -213 4 -11 -10 -2 -32 23 -48 51 -72 115 -72 194 0 58 0 58 30 58 l30
0 1 -62z m739 4 c0 -79 -24 -143 -72 -194 -22 -25 -36 -34 -32 -23 24 65 43
158 43 213 l1 62 30 0 c30 0 30 0 30 -58z m-350 -467 c0 -41 27 -145 46 -172
7 -12 14 -25 14 -28 0 -3 -49 -5 -110 -5 -60 0 -110 2 -110 5 0 3 7 16 14 28
19 27 46 131 46 172 0 29 1 30 50 30 49 0 50 -1 50 -30z m210 -340 l0 -95
-260 0 -260 0 0 95 0 95 260 0 260 0 0 -95z"
        />
      </g>
    </svg>
  );
}
