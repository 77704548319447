import TrophyIcon from "../../assets/icons/TrophyIcon";
import { TrophyColumn } from "./styles/TrophyDisplay.styled";

interface Props {
  title: string;
  value: number | undefined | null;
}

const getTrophyColor = (value: number | undefined | null): string => {
  if (value) {
    if (value < 1) {
      return "#f6b600";
    } else if (value <= 20) {
      return "#f6b600";
    } else if (value <= 35) {
      return "#aeaeae";
    } else if (value <= 50) {
      return "#9c7456";
    }
  }
  return "#000";
};

const Trophy = ({ title, value }: Props) => {
  const processedValue = !value ? value : value < 1 ? 1 : value;
  return (
    <TrophyColumn>
      <div className="trophy-column__title">{title}</div>
      <div className="trophy-column__trophy">
        <TrophyIcon color={getTrophyColor(value)} />
      </div>
      <div className="trophy-column__value">{value ? `TOP ${processedValue}%` : "--"}</div>
    </TrophyColumn>
  );
};

export default Trophy;
