import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { palette } from "../../styles/styleUtils";

const Container = styled.div`
  max-width: 454px;
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 18px;
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
    max-width: 100%;
  }
`;

const PlayerImage = styled.img`
  height: 218px;
  width: 454px;
  // background-color: grey;
  margin-bottom: 18px;

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

const Description = styled.div`
  max-height: 262px;
  height: 100%;
  line-height: 23px;
  overflow: auto;
  letter-spacing: 0.05px;
`;

const ReadMore = styled.a`
  color: ${palette.blue};
  font-size: 20px;
  letter-spacing: 0.4px;
  text-decoration: none;
`;

const SleeperPicks = ({ sleeper_pick }) => {
  return (
    <Container>
      <h1 className="blue">Sleeper Picks</h1>
      <PlayerImage src={sleeper_pick?.photo_url} alt="sleeper pick photo" />
      <Description>{sleeper_pick?.body}</Description>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ReadMore href="#" target="_blank">
          Read More
        </ReadMore>
      </div>
    </Container>
  );
};

export default SleeperPicks;
