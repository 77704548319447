import { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../../hooks/useBreakPoints";
import { NavLink } from "../../styles/globalStyles";
import { palette, pxToRem } from "../../styles/styleUtils";
import PlayerBio from "./PlayerBio";
import YtdResults from "./YtdResults";
import YtdStats from "./YtdStats";

type Category = "bio" | "stats" | "results";

const PlayerDetails = ({ player }) => {
  const [catergory, setCategory] = useState<Category>("stats");

  const player_details = [
    {
      label: "Birthday",
      value: player?.birthday,
      className: "birthday",
    },
    {
      label: "Birthplace",
      value: player?.birthplace,
      className: "birthplace",
    },
    {
      label: "College",
      value: player?.college,
      className: "college",
    },
    {
      label: "Turned Pro",
      value: player?.turned_pro,
      className: "turned_pro",
    },
    {
      label: "Rank",
      value: player?.stats?.rank == 999999999 ? "N/A" : player?.stats?.rank,
      className: "rank",
    },
  ];
  return (
    <PlayerDetailsContainer>
      <div className="header">Player Details</div>
      <NavLinkContainer>
        <NavLink className={catergory == "stats" ? "active" : ""} onClick={() => setCategory("stats")}>
          YTD Stats
        </NavLink>
        <NavLink className={catergory == "results" ? "active" : ""} onClick={() => setCategory("results")}>
          YTD Results
        </NavLink>
        <NavLink className={catergory == "bio" ? "active" : ""} onClick={() => setCategory("bio")}>
          bio
        </NavLink>
      </NavLinkContainer>
      <PlayerPersonalDetails>
        <div className="mobile-top">
          <img
            src={`https://pga-tour-res.cloudinary.com/image/upload/t_headshots_leaderboard_l/headshots_${player?.remote_id}.png`}
            alt={player?.first_name + player?.last_name}
          />
          <NameSection>
            <div className="name">
              {player?.first_name} {player?.last_name}
            </div>
            <div className="body-measurements">
              <div className="nation">
                <img
                  src={`https://res.cloudinary.com/pgatour-prod/flags/${player?.country}.png`}
                  alt={player?.country}
                />
                {player?.country}
              </div>
              <div className="height">{player?.height?.replace(/-/g, "'") + '"'}</div>
              <div className="weight">{player?.weight} LBS</div>
            </div>
          </NameSection>
        </div>

        <PersonalSection>
          {player_details?.map((detail, index) => {
            return (
              <div key={index} className={`${detail.className} personal-container`}>
                <div className="label">{detail.label}:</div>
                <div className="value">{detail.value}</div>
              </div>
            );
          })}
        </PersonalSection>
      </PlayerPersonalDetails>
      {catergory == "stats" && <YtdStats player={player} />}
      {catergory == "results" && <YtdResults tournament_player_data={player?.tournament_players} />}
      {catergory == "bio" && <PlayerBio player={player} />}
    </PlayerDetailsContainer>
  );
};

const PlayerDetailsContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 966px;
  padding: 36px;
  max-height: 70vh;
  overflow: auto;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 10px;
    max-height: 650px;
  }
  .header {
    font-size: ${pxToRem(26)};
    font-weight: 700;
    color: ${palette.blue};
    text-transform: uppercase;
    width: 100%;
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 18px;
    }
  }
  img {
    width: 158px;
    height: 158px;
    @media (max-width: ${breakpoints.tablet}) {
      width: 97px;
      height: 97px;
    }
  }
`;

const NavLinkContainer = styled.div`
  display: flex;
  max-width: 400px;
  margin: 40px 0;
`;

const PlayerPersonalDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 42px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-flow: column;
    align-items: inherit;
  }
  .body-measurements {
    display: flex;
    justify-content: space-between;
    max-width: 185px;
    @media (max-width: ${breakpoints.tablet}) {
      max-width: 170px;
    }

    div:nth-child(2) {
      padding: 0 20px;
      border-left: 1px solid black;
      border-right: 1px solid black;
      @media (max-width: ${breakpoints.tablet}) {
        padding: 0 10px;
      }
    }
  }
  .mobile-top {
    display: flex;
    width: max-width: 300px;
    flex: 1;
    align-items: center;
    @media (max-width: ${breakpoints.tablet}) {
      margin-bottom: 20px;
    }
  }
`;

const NameSection = styled.div`
  max-width: 300px;
  flex: 1;
  margin-left: 20px;
  .name {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 22px;
    }
  }
  .body-measurements {
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 14px;
    }
    .nation {
      display: flex;
      align-items: center;
      img {
        height: 24px;
        width: 37px;
        margin-right: 5px;
        @media (max-width: ${breakpoints.tablet}) {
          height: 15px;
          width: 22px;
        }
      }
    }
  }
`;

const PersonalSection = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 400px;
  flex: 1;
  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }

  .personal-container {
    display: flex;
    .label {
      width: 91px;
    }
    .value {
      white-space: nowrap;
      font-weight: 700;
    }
  }
`;

export default PlayerDetails;
