import Image from "next/image";
import styled from "styled-components";
import { breakpoints, useBreakpoints } from "../../hooks/useBreakPoints";
import { IPlayer } from "../../types/interfaces/RosterTypes";
import { PlayerDataLanding } from "../Roster/RosterSelect/styles/PlayerCard.styled";

const PositionBadge = styled.div`
  align-items: center;
  background-color: white;
  background: radial-gradient(white 50%, transparent 51%), conic-gradient(transparent 0deg 0deg, #d5d5d5 0deg 360deg),
    conic-gradient(#ff7575 0deg, #ff7575 90deg, #ff7575 180deg, #ff7575);
  border-radius: 50%;
  display: flex;
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  left: 4px;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 10px;
    height: 30px;
    left: 0;
    width: 30px;
  }
`;

interface Props {
  pick: IPlayer;
  isActive: boolean;
  slotIndex: number;
  onClick: (slot: number, playerId: number) => void;
}
const LandingRosterPlayer = ({ pick, isActive, slotIndex, onClick }: Props) => {
  const { isEmpty } = pick || {};
  const { isRosterMobile, isMobileView } = useBreakpoints();
  const getHeadshot = (id?: string) =>
    `https://pga-tour-res.cloudinary.com/image/upload/t_headshots_leaderboard_l/headshots_${id}.png`;
  return (
    <PlayerDataLanding className="landing">
      <div className="outline" />
      <button onClick={() => onClick(slotIndex, pick?.player_id)} className={isActive ? "active" : ""}>
        {pick.position && <PositionBadge>{pick.position}</PositionBadge>}
        <div className="landing-headshot">
          {isEmpty ? (
            <Image
              src="/images/PGA-empty-player.png"
              height={isMobileView ? 61 : isRosterMobile ? 86 : 121}
              width={isMobileView ? 61 : isRosterMobile ? 86 : 121}
              alt="empty player selection image"
              unoptimized={true}
            />
          ) : (
            <>
              <img
                src={getHeadshot(pick && pick.remote_id_formatted)}
                alt={pick && pick.first_name + pick.last_name}
                height={isMobileView ? 61 : isRosterMobile ? 86 : 121}
                width={isMobileView ? 61 : isRosterMobile ? 86 : 121}
              />
            </>
          )}
        </div>
        <div className="landing-pick-name">{pick && pick.first_name + " " + pick.last_name}</div>
      </button>
    </PlayerDataLanding>
  );
};

export default LandingRosterPlayer;
