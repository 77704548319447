import { useRouter } from "next/dist/client/router";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import { breakpoints } from "../../hooks/useBreakPoints";
import { Button } from "../../styles/buttons.styled";
import { pxToRem } from "../../styles/styleUtils";
import { StyledLeagueListTable } from "../Leagues/styles/LeagueListTable.styled";
import { Cell, HeaderCell, Row } from "../Table/Table";
import { UserDetailsWrapper } from "./LandingAuth.styled";

const LandingLeaguesTable = styled.div`
  margin-top: 50px;
  .row {
    justify-content: space-between;
  }
  th {
    font-size: ${pxToRem(14)};
    @media (max-width: 1179px) {
      font-size: ${pxToRem(12)};
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      font-size: ${pxToRem(16)};
      &.header-cell {
        font-size: ${pxToRem(14)};
      }
    }
  }
  .league-name {
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
      text-underline-position: under;
    }
    button {
      margin-left: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 190px !important;
      @media (max-width: 1179px) {
        width: 120px !important;
      }
      @media (max-width: ${breakpoints.rosterMobile}) {
        width: 150px !important;
      }
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      font-size: ${pxToRem(16)} !important;
      flex: 0 0 200px !important;
    }
    @media (max-width: 1179px) {
      flex: 0 0 120px !important;
      font-size: ${pxToRem(13)} !important;
    }
    flex: 0 0 160px !important;
    font-size: ${pxToRem(16)} !important;
  }
`;

const LeaguesTable = () => {
  const { user } = useAuth();
  const router = useRouter();

  const ordinal_suffix = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };
  const favoriteId = user?.pgaroster?.entry?.group_memberships?.findIndex((g) => g.is_default === true);
  const defaultGroup = favoriteId !== undefined ? user?.pgaroster?.entry?.groups?.[favoriteId] : null;
  const defaultGroupMembership =
    favoriteId !== undefined ? user?.pgaroster?.entry?.group_memberships?.[favoriteId] : null;
  return (
    <LandingLeaguesTable>
      <UserDetailsWrapper>
        <div className="entry-name">MY LEAGUES</div>
        <Button onClick={() => router.push("/leagues")}>JOIN LEAGUES</Button>
      </UserDetailsWrapper>
      <StyledLeagueListTable>
        <thead>
          <Row className="header-row">
            <HeaderCell className="league-name"></HeaderCell>
            <HeaderCell>Tournament</HeaderCell>
            <HeaderCell>Segment</HeaderCell>
            <HeaderCell>Season</HeaderCell>
          </Row>
        </thead>
        <Row>
          <Cell className="league-name">
            <button onClick={() => router.push(`/leaderboard`)}>Overall</button>
          </Cell>
          <Cell className="stat">
            {user?.pgaroster?.entry?.tournament_rank && user?.pgaroster?.entry?.tournament_rank !== 999999999
              ? ordinal_suffix(user?.pgaroster?.entry?.tournament_rank)
              : "--"}
          </Cell>
          <Cell className="stat">
            {user?.pgaroster?.entry?.segment_rank && user?.pgaroster?.entry?.segment_rank !== 999999999
              ? ordinal_suffix(user?.pgaroster?.entry?.segment_rank)
              : "--"}
          </Cell>
          <Cell className="stat">
            {" "}
            {user?.pgaroster?.entry?.season_rank && user?.pgaroster?.entry?.season_rank !== 999999999
              ? ordinal_suffix(user?.pgaroster?.entry?.season_rank)
              : "--"}
          </Cell>
        </Row>
        {defaultGroup && defaultGroupMembership && (
          <Row>
            <Cell className="league-name">
              <button onClick={() => router.push(`/leagues/${defaultGroup?.group_id}`)}>{defaultGroup?.name}</button>
            </Cell>
            <Cell className="stat">
              {defaultGroupMembership?.tournament_rank && defaultGroupMembership?.tournament_rank !== 999999999
                ? ordinal_suffix(defaultGroupMembership?.tournament_rank)
                : "--"}
            </Cell>
            <Cell className="stat">
              {defaultGroupMembership?.segment_rank && defaultGroupMembership?.segment_rank !== 999999999
                ? ordinal_suffix(defaultGroupMembership?.segment_rank)
                : "--"}
            </Cell>
            <Cell className="stat">
              {defaultGroupMembership?.season_rank && defaultGroupMembership?.season_rank !== 999999999
                ? ordinal_suffix(defaultGroupMembership?.season_rank)
                : "--"}
            </Cell>
          </Row>
        )}
        {user?.pgaroster?.entry?.groups?.map((group, i) => {
          const tournament_rank = user?.pgaroster?.entry?.group_memberships?.[i]?.tournament_rank;
          const segment_rank = user?.pgaroster?.entry?.group_memberships?.[i]?.segment_rank;
          const season_rank = user?.pgaroster?.entry?.group_memberships?.[i]?.season_rank;
          if (i !== favoriteId || defaultGroup === null || defaultGroupMembership === null) {
            return (
              <Row key={i}>
                <Cell className="league-name">
                  <button onClick={() => router.push(`/leagues/${group?.group_id}`)}>{group?.name}</button>
                </Cell>
                <Cell className="stat">
                  {tournament_rank && tournament_rank !== 999999999 ? ordinal_suffix(tournament_rank) : "--"}
                </Cell>
                <Cell className="stat">
                  {segment_rank && segment_rank !== 999999999 ? ordinal_suffix(segment_rank) : "--"}
                </Cell>
                <Cell className="stat">
                  {season_rank && season_rank !== 999999999 ? ordinal_suffix(season_rank) : "--"}
                </Cell>
              </Row>
            );
          }
        })}
      </StyledLeagueListTable>
    </LandingLeaguesTable>
  );
};

export default LeaguesTable;
