import { breakpoints, useBreakpoints } from "../../hooks/useBreakPoints";
import TournamentDisplay from "../Roster/TournamentDisplay";
import TrophyDisplay from "../Roster/TrophyDisplay";
import Editorials from "./Editorials";
import { AuthContainer, InfoRow, PicksContainer } from "./LandingAuth.styled";
import LandingRoster from "./LandingRoster";
import LeaguesTable from "./LeaguesTable";
import UserDetails from "./UserDetails";
import { adIds } from "../../constants/Ads";
import Ad from "../Ad";
import { useEffect, useState } from "react";
import { cordovaCheck } from "../../utils/Cordova";
import RolloverList from "./RolloverList";
import { useAuth } from "../../hooks/useAuth";
import ScrolledAd from "../ScrolledAd";
import ExpertPicks from "./ExpertPicks";
import PctOrFpts from "./PctOrFpts";
import styled from "styled-components";
import MobileTabList from "./MobileTabList";
import { useTournamentQuery } from "../../hooks/Queries/useGame";

export default function LandingAuth(): JSX.Element {
  const { isMobileAds, isTabletAds, isMobileView } = useBreakpoints();
  const [isCordova, setIsCordova] = useState<boolean>(true);
  const { data: tournyData } = useTournamentQuery();
  const { user } = useAuth();
  useEffect(() => {
    setIsCordova(cordovaCheck());
  }, [typeof window]);

  const [showPlayerStats, setShowPlayerStats] = useState(false);
  const tourneyHasStarted = tournyData?.started === true;

  return (
    <AuthContainer>
      {!isMobileView ? (
        <>
          <PicksContainer>
            <div className="picks-container__picks">
              {!isMobileView && <TournamentDisplay />}
              <UserDetails />
              <LandingRoster />
            </div>
            <div className="picks-container__details">
              <TrophyDisplay />
              <LeaguesTable />
            </div>
          </PicksContainer>

          {!isMobileAds && !isCordova && (
            <ScrolledAd>
              <Ad adId={isTabletAds ? adIds.TABLET_LANDING_LOGGED : adIds.DESKTOP_LANDING_LOGGED} pos={"middle"} />
            </ScrolledAd>
          )}
          {user?.pgaroster?.rollover_groups && user?.pgaroster?.rollover_groups?.length > 0 && <RolloverList />}
          <InfoRow style={{ marginBottom: "60px" }}>
            <ExpertPicks />
            <RightSide>
              <PctOrFpts category="pct" />
              {tourneyHasStarted ? <PctOrFpts category="tournament_pts" /> : <PctOrFpts category="fpts" />}
            </RightSide>
          </InfoRow>
          <Editorials />
        </>
      ) : (
        <>
          <MobileTabList showPlayerStats={showPlayerStats} setShowPlayerStats={setShowPlayerStats} />
          {!showPlayerStats ? (
            <PicksContainer>
              <div className="picks-container__picks">
                {!isMobileView && <TournamentDisplay />}
                <UserDetails />
                <LandingRoster />
              </div>
              <div className="picks-container__details">
                <TrophyDisplay />
                <LeaguesTable />
              </div>
              {user?.pgaroster?.rollover_groups && user?.pgaroster?.rollover_groups?.length > 0 && <RolloverList />}
            </PicksContainer>
          ) : (
            <InfoRow style={{ marginBottom: "60px" }}>
              <ExpertPicks />
              <RightSide>
                <PctOrFpts category="pct" />
                {tourneyHasStarted ? <PctOrFpts category="tournament_pts" /> : <PctOrFpts category="fpts" />}
              </RightSide>
            </InfoRow>
          )}

          <Editorials />
        </>
      )}
    </AuthContainer>
  );
}

const RightSide = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
  }
  @media (max-width: ${breakpoints.mobile}) {
    flex-flow: row;
  }
`;
